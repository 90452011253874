import React from "react";
import useCssClassByViewport from "../../hooks/useCssClassByViewport";
import Btn from "../atoms/Btn";
import Heading from "../atoms/Heading";
// import BtnAction from "../atomsComponents/BtnAction/BtnAction";

function Paragraph({
  titleClass,
  titleText,
  paragraphText,
  paragraphClasses,
  paragraphIsBold,
  callToActionText,
  callToActionClass,
  callToActionOnClickFn,
  callToActionIcon,
  callToActionIconClass,
  callToActionLinkingSection,
}) {
  return (
    <div>
      {titleText && (
        <Heading numType={1} classes={`${titleClass}`} text={titleText} />
      )}
      <p
        className={`${paragraphIsBold ? "bold" : ""} ${useCssClassByViewport(
          "", //"mt20 mb20",
          "",
          "", //" mt20 mb24",
          "",
          ""
        )} ${paragraphClasses}`}
      >
        {paragraphText}
      </p>
      <Btn
        text={callToActionText}
        onClick={callToActionOnClickFn}
        classes={`btnPrimary ${callToActionClass}`}
        iconClass={callToActionIconClass}
        icon={callToActionIcon}
        linkTo={callToActionLinkingSection}
      />
    </div>
  );
}

export default Paragraph;
