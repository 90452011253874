import { memo } from "react";

// Create components
import { ReactComponent as icnPlay } from "./iconly/Play.svg";
import { ReactComponent as icnInfo } from "./iconly/InfoCircle.svg";
import { ReactComponent as icnPlus } from "./iconly/Plus.svg";
import { ReactComponent as icnCart } from "./iconly/Buy.svg";
import { ReactComponent as icnArrowDown } from "./iconly/ArrowDown.svg";
import { ReactComponent as icnFigmaArrowUp } from './figma-made-icons/ArrowIconUp.svg'
import { ReactComponent as icnFigmaArrowDown } from './figma-made-icons/ArrowIconDown.svg'
import { ReactComponent as icnFigmaArrowRight } from './figma-made-icons/ArrowIconRight.svg'
import { ReactComponent as icnFigmaArrowLeft } from './figma-made-icons/ArrowIconLeft.svg'

// Export components
export const IcnPlay = memo(icnPlay);
export const IcnInfo = memo(icnInfo);
export const IcnPlus = memo(icnPlus);
export const IcnCart = memo(icnCart);
export const IcnArrowDown = memo(icnArrowDown);
export const IcnFigmaArrowUp = memo(icnFigmaArrowUp);
export const IcnFigmaArrowDown = memo(icnFigmaArrowDown);
export const IcnFigmaArrowRight = memo(icnFigmaArrowRight);
export const IcnFigmaArrowLeft = memo(icnFigmaArrowLeft);

export const ICONS = {
  IcnPlay: IcnPlay,
  IcnInfo: IcnInfo,
  IcnPlus: IcnPlus,
  IcnCart: IcnCart,
  IcnArrowDown: IcnArrowDown,
  IcnFigmaArrowUp: IcnFigmaArrowUp,
  IcnFigmaArrowDown: IcnFigmaArrowDown,
  IcnFigmaArrowRight: IcnFigmaArrowRight,
  IcnFigmaArrowLeft: IcnFigmaArrowLeft,
};
