import React from "react";
import useCssClassByViewport from "../../../hooks/useCssClassByViewport";
import Heading from "../../atoms/Heading";

const Card = ({ number, img, title, intro, description, containerClasses }) => {
  const Style = {
    Card: `Card hoverShadow p2vw fitContent mAuto row  noSelect ${useCssClassByViewport(
      "w72vw",
      "",
      "w44pct", //"w20vw",
      "",
      ""
    )} ${containerClasses}`,
    Title: `mb16`,
    Paragraph: `mb0`,
    Intro: `bold mr8 txtLeft block`,
    Count: `Count mr28 mt8 ${useCssClassByViewport("vCenter","","","","")}`,
  };
  return (
    <div className={Style.Card}>
      {img && <div></div>}
      {title && <Heading numType={3} text={title} classes={Style.Title} />}
      {/* {description && ( */}
        <>
          <span className={number ? Style.Count : 'hide'}>{number}</span>
          <p className={Style.Paragraph}>
            {intro && <strong className={Style.Intro}>{intro}</strong>}
            {description}
          </p>
        </>
      {/* )} */}
    </div>
  );
};

export default Card;
