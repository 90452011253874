import { APP_ACTIONS } from "../actions/types";

export const APP_INITIAL_STATE = {
  currentHomeSection: "Main",
};

const appReducer = (state = APP_INITIAL_STATE, action) => {
  switch (action.type) {
    case APP_ACTIONS.SET_CURRENT_HOME_SECTION:
      return {
        ...state,
        currentHomeSection: action.payload,
      };
    default:
      return state;
  }
};

export default appReducer;
