import React from "react";
import { LaborLogoMemo } from "../../../assets/memoSVG";
import useCssClassByViewport from "../../../hooks/useCssClassByViewport";

const Footer = () => {
  return (
    <footer className="AppPadding pt20 bglight">
      <div className={useCssClassByViewport("mAuto", "", "flex", "", "")}>
        <div className="FooterColumn">
          <h2>Microfactoria</h2>
          <p>
            La forma más eficiente de producir, distribuir y comercializar
          </p>
        </div>
        <div className="FooterColumn">
          <h2>Contacto</h2>
          <ul>
            <li>
              <p>info@microfactoria.com</p>
            </li>
            <li>
              <p className="bold">(+598) 98 67 70 87</p>
            </li>
            <li>
              <p>Laboriot S.A.S</p>
            </li>
            <li>
              <p>Montevideo, Uruguay</p>
            </li>
          </ul>
          {/* FooterIcons */}
          <a href="https://www.instagram.com/labor.dt/">
            <i className="fab fa-instagram FooterIcons"></i>
          </a>
          <a href="https://www.facebook.com/Labor-Development-Technology-2271010156473458/">
            <i className="fab fa-facebook-f FooterIcons"></i>
          </a>
          {/* <a href="https://www.instagram.com/labor.dt/">
                        <i class="fab fa-linkedin-in FooterIcons"></i>
                    </a> */}
        </div>
      </div>

      <p
        className={`mb0 ${useCssClassByViewport(
          "littleText mt60 pb24 txtCenter fullW",
          "",
          "littleText mt60 pb24 mb0 txtRight fullW",
          "",
          ""
        )}`}
      >
        <span style={{ lineHeight: "12px" }}>
          <span className="opacity6">
            © 2021 Todos los derechos reservados.
          </span>
          <span
            className={useCssClassByViewport(
              "mb12 ml8 block",
              "",
              "mb12 ml8",
              "",
              ""
            )}
          >
            <a className="txtDecoNone extraBold" href="#">
              Microfactoria
            </a>
          </span>
        </span>
      </p>
    </footer>
  );
};

export default Footer;
