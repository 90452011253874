import React from "react";
import { Element } from "react-scroll";
import About from "../components/pageComponents/Home/About";
import Contact from "../components/pageComponents/Home/Contact";
import Main from "../components/pageComponents/Home/Main";
import Microfactoria from "../components/pageComponents/Home/Microfactoria";
import MiPyMes from "../components/pageComponents/Home/MiPyMes";

const Home = () => {
  return (
    <div>
      {/* <HomeNavigator/> */}
      <Element id="main-section" name="main-section" className="AppPadding">
        <Main />
      </Element>

      <Element id="microfactoria-section" name="microfactoria-section" className="AppPadding">
        <Microfactoria />
      </Element>
      
      <Element id="mipymes-section" name="mipymes-section" className="AppPadding">
        <MiPyMes />
      </Element>
      
      <Element id="about-section" name="about-section" >
        <About />
      </Element>

      <Element id="contact-section" name="contact-section">
        <Contact />
      </Element>
    </div>
  );
};

export default Home;
