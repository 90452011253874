import React, { useRef } from "react";
import { IcnFigmaArrowRight } from "../../../assets/Icons";
import { MicrofactoriaIllustration } from "../../../assets/memoSVG";
import { useApp } from "../../../context/AppContext/AppContext";
import useCssClassByViewport from "../../../hooks/useCssClassByViewport";
import useOnScrollReachElement from "../../../hooks/useOnScrollReachElement";
import ImgParagraphRow from "../../molecules/ImgParagraphRow";
import Paragraph from "../../molecules/Paragraph";

const imgUrl = `https://images.unsplash.com/photo-1579546929518-9e396f3cc809?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHx8&w=1000&q=80`

const Main = () => {
  const main = useRef(null);
  useOnScrollReachElement("Main")

  const Style = {
    Main: `${useCssClassByViewport("txtCenter", "", "pt160", "", "")} `,
    HeroText:`heroText mb8 fcPrimary ${useCssClassByViewport("hide", "", "block", "", "")}`,
    HeroParagraph:`biggestText maxW400 mb20 ${useCssClassByViewport("bold mb40 biggestText mt20", "bold mb40 biggestText mt40", "", "", "")}`,
    CallToAction: `${useCssClassByViewport("mAuto", "", "mrAuto", "", "")}`,
    ImgParagraphRow: `${useCssClassByViewport("column mt20 mb0", "", "mb100", "", "")}`
  };

  return (
    <div className={Style.Main} id={`Main`} ref={main}>
      <ImgParagraphRow
        ParagraphComponent={
          <Paragraph
            titleText="Microfactoria"
            titleClass={Style.HeroText}
            paragraphText="La forma más eficiente de  producir, distribuir y comercializar"
            paragraphClasses={Style.HeroParagraph}
            callToActionText="Leer más"
            callToActionIcon={<IcnFigmaArrowRight/>}
            callToActionClass={Style.CallToAction}
            callToActionLinkingSection="microfactoria-section"
          />
        }
        containerClasses={Style.ImgParagraphRow}
        // imgClasses={"h260"}
        // img={imgUrl}
        svg={<MicrofactoriaIllustration/>}
      />
    </div>
  );
};

export default Main;
