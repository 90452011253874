import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";
import useCssClassByViewport from "../../../hooks/useCssClassByViewport";
import { useApp } from "../../../context/AppContext/AppContext";

const Header = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const header = useRef(null);

  // Using AppContext
  const { setContext, getContext } = useApp();
  const { currentHomeSection } = getContext;

  //#region ---- Styles ----
  // Style depending on viewport width.
  const Style = {
    HeaderContent: useCssClassByViewport(
      "py28x0 flex vCenter",
      "",
      "my0x3vw py44x0 flex vCenter h56",
      "",
      ""
    ),
    Menu: useCssClassByViewport(
      showMobileMenu ? "MobileMenu" : "hide",
      "",
      "Menu",
      "",
      ""
    ),
  };
  //#endregion ---- Styles ----

  // Setting body padding-top after getting the Header height.
  useEffect(() => {
    setTimeout(() => {
      let body = document.querySelector("body");
      if (
        body &&
        body instanceof HTMLElement &&
        header.current &&
        header.current instanceof HTMLElement
      ) {
        body.style.paddingTop = header.current.offsetHeight + "px";
      }
    }, 40);
  }, [header]);

  return (
    <header
      id="Header"
      ref={header}
      className={`AppPadding availableW fixed top0 ${
        showMobileMenu ? "shadow borderB pb8" : ""
      } ${
        document.getElementById(currentHomeSection)
          ? document
              .getElementById(currentHomeSection)
              .className.includes("BlackSection")
            ? "BlackSection"
            : "WhiteSection"
          : "WhiteSection"
      } `}
    >
      <div className={Style.HeaderContent}>
        <div className="Logo">
          <RouterLink to="/" className="MicrofactoriaLogo">
            Microfactoria
          </RouterLink>
        </div>
        <nav className="mlAuto center">
          <div className={Style.Menu}>
            <ul className={useCssClassByViewport("", "", "center", "", "")}>
              <li>
                <Link
                  href="/"
                  to="main-section"
                  spy={true}
                  smooth={true}
                  duration={500}
                  onClick={() => setShowMobileMenu(false)}
                  className={currentHomeSection === "Main" ? "active" : ""}
                >
                  inicio
                </Link>
              </li>
              {/* <li>
                {window.l}
                <RouterLink to="/product">product</RouterLink>
              </li> */}
              <li>
                <Link
                  href="#"
                  to="about-section"
                  spy={true}
                  smooth={true}
                  duration={500}
                  onClick={() => setShowMobileMenu(false)}
                >
                  nosotros
                </Link>
              </li>
              <li className="mr0">
                <Link
                  href="#"
                  to="contact-section"
                  spy={true}
                  smooth={true}
                  duration={500}
                  onClick={() => setShowMobileMenu(false)}
                >
                  contacto
                </Link>
              </li>
            </ul>
          </div>
        </nav>

        {/* <button
          className={useCssClassByViewport("BtnMenu", "", "hide", "", "")}
          onClick={() => handleMenu(() => setAccessModalToShow("SIGNIN"))}
        >
          <div className={showMobileMenu ? "transition" : "hide transition"}>
            <FAClose />
          </div>
          <div className={showMobileMenu ? "hide" : "transition"}>
            <FABars />
          </div>
        </button> */}
      </div>

      {/* {accessModalToShow !== "" && (
        <Access
          accessModalToShow={accessModalToShow}
          handleSetAccessMode={handleSetAccessMode}
          onClose={() => setAccessModalToShow("")}
        />
      )} */}

      {/* {showDropDownMenu && (
        <DropDownMenu onClose={() => setShowDropDownMenu(false)} />
      )} */}
    </header>
  );
};

export default Header;
