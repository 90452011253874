import React, { useState } from "react";
import { Link } from "react-router-dom";
import useCssClassByViewport from "../../../hooks/useCssClassByViewport";
import useOnScrollReachElement from "../../../hooks/useOnScrollReachElement";
import Btn from "../../atoms/Btn";
import Heading from "../../atoms/Heading";
import ImgParagraphRow from "../../molecules/ImgParagraphRow";
import Paragraph from "../../molecules/Paragraph";
import Card from "./Card";
import LogoMicrofactoria from "../../../assets/Company/IconLogo2.png";

const imgUrl = `https://images.unsplash.com/photo-1579546929518-9e396f3cc809?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHx8&w=1000&q=80`;

const Microfactoria = () => {
  useOnScrollReachElement("Microfactoria");

  const Style = {
    Microfactoria: `SectionSeparator pt100 ${useCssClassByViewport(
      "",
      "",
      "",
      "",
      ""
    )} `,
    Advantages: ` SectionSeparator px4vw`,
    AdvantagesTitle: ` SectionSeparator3 pb40`,
    Cards: `flexWrap hCenter spaceBetween gap28 `,
    AllAdvantages: `center`,
    imgStyle: {
      backgroundImage: `url(${imgUrl})`,
      backgroundSize: "cover",
      backgroundPosition: "bottom",
    },
    img: `fullW h200 my60 SectionSeparator2`,
    ButtonsSection: `SectionSeparator ${useCssClassByViewport(
      "spaceBetween column center h188",
      "",
      "spaceBetween",
      "",
      ""
    )}`,
    Button: `${useCssClassByViewport(
      "w100pct",
      "",
      "w48pct",
      "",
      ""
    )} center h80 radius8 extraBold fcWhite bgPrimary upper bcPrimary txtDecoNone`,
  };

  return (
    <div id={`Microfactoria`} className={Style.Microfactoria}>
      <div className="SectionSeparator">
        <Paragraph
          titleClass={"mb56"}
          titleText={`El modelo de Microfactoria`}
          paragraphClasses={`txtCenter AppPadding`}
          paragraphText="Este concepto nace con un objetivo claro, lograr sustentabilidad y eficiencia productiva. Para pensar en sustentabilidad hay que desarrollar modelos que trabajen bajo esta lógica y con estos parámetros, que obligan a cambiar la forma tradicional de producción y comercialización.  Es por esto que desarrollamos la Microfactoria, una forma eficiente y sustentable de producir. Este modelo planteado genera nuevas oportunidades de negocio para MiPyMes que busquen insertarse en el mundo sustentable y quieran hacerlo con un desarrollo de carácter nacional.  Priorizando siempre la eficiencia en la utilización de los recursos, tanto económicos como naturales, para una adecuada implementación equilibrada de los mismos."
        />
      </div>
      <div className={Style.Advantages}>
        <Heading
          numType={2}
          text={`Ventajas del modelo:`}
          classes={Style.AdvantagesTitle}
        />
        <div className={Style.Cards}>
          {/* Ventaja 1 */}
          <Card
            number={1}
            intro={`No se requiere de una planta industrial`}
            description={`para la elaboración de los productos, reduciendo a cero los gastos
            en este aspecto.`}
          />

          {/* Ventaja 2 */}
          <Card
            number={2}
            intro={`Compacta.`}
            description={`Sus dimensiones reducidas permiten una mayor adaptabilidad al espacio físico. Siendo ideal para el uso portátil, por la facilidad de su traslado.`}
          />

          {/* Ventaja 3 */}
          <Card
            number={3}
            intro={`Menor costo de producción.`}
            description={`Mayor ganancia y competitividad en el mercado al 
            poder acceder a mejores precios para el público.`}
          />

          {/* Ventaja 4 */}
          <Card
            number={4}
            intro={`Fácil de usar.`}
            description={`Solo se debe elegir el producto, la cantidad, insertar 
            el envase y dar inicio; el resto lo hace la máquina.`}
          />

          {/* Ventaja 5 */}
          <Card
            number={5}
            intro={`Reducción de sistemas logísticos.`}
            description={`Disminución del costo asociado a sistemas logísticos de transporte y mantenimiento de inventarios.`}
          />

          {/* Ventaja 6 */}
          <Card
            number={6}
            intro={`Menos emisiones de co2.`}
            description={`Se reducen 2,64kg de gas co2 por litro no 
            consumido de gasoil, ahorro generado por contar 
            con un sistema logístico más pequeño.`}
          />

          {/* Ventaja 7 */}
          <Card
            number={7}
            intro={`Uso eficiente de recursos.`}
            description={`Reduce el consumo de agua y reaprovecha la misma para los ciclos de limpieza en la producción. 
            Cuenta con sistemas de bajo consumo, que 
            garantizan el uso eficiente de la energía eléctrica.`}
          />

          {/* Ventaja 8 */}
          <Card
            number={8}
            intro={`Se fomenta el uso de la filosofía de reutilización,`}
            description={`al darle uso a los envases de otros productos como ser bebidas. Siendo este un principio fundamental de la economía circular.`}
          />

          {/* Ventaja 9 */}
          <Card
            number={9}
            intro={`Alcanzar más puntos de venta a bajo costo,`}
            description={`con una logística mínima de distribución. Permitiendo la posibilidad de ampliar el mercado meta, y abarcar más puntos de venta en todo el país.`}
          />

          {/* Ventaja 10 */}
          <Card
            number={10}
            intro={`Innovación en el sector,`}
            description={`lo que genera un mayor margen de competitividad.`}
          />

          {/* Ventaja 11 */}
          <Card
            number={11}
            intro={`Baja inversión inicial con altos rendimientos a corto plazo`}
            description={``}
          />

          {/* Ventaja 12 */}
          <Card
            number={12}
            intro={`Promoción de tecnología verde,`}
            description={`en un segmento de mercado con amplio crecimiento y expansión.`}
          />
        </div>
      </div>

      <div className="">
        {/* AppPadding */}
        <Heading
          text={`¿Que es una Microfactoria?`}
          numType={1}
        />
        <ImgParagraphRow
          containerClasses={`SectionSeparator`}
          ParagraphComponent={
            <p>
              La Microfactoria es una fábrica a escala robotizada,
              de dimensiones reducidas, que permite elaborar diferentes
              variedades de productos de limpieza. Con esta innovadora
              tecnología se generan nuevas oportunidades de negocio para MiPyMes
              que busquen insertarse en el mundo sustentable y quieran hacerlo
              con un desarrollo de carácter nacional. Solamente es necesario
              encargarse del área comercial, todo aspecto relacionado con la
              parte técnica y química se encarga la Microfactoria. Con este
              sistema, hacer un detergente será tan fácil como oprimir un botón
              y cargar una botella. Dependiendo del modelo a seguir, se podrá
              utilizar una Microfactoria fija o móvil.
            </p>
          }
          imgClasses="h200 imgCenter contain noRepeat"
          img={LogoMicrofactoria}
        />
        <Heading
          text={`Características relevantes`}
          numType={2}
          classes={`mb56`}
        />
        <div className={Style.Cards}>
          <Card
            intro={`Portabilidad y tamaño,`}
            description={`tamaño reducido lo cual hace fácil su transporte e instalación en todo tipo de lugares.`}
          />
          <Card
            intro={`Capacidad de producción,`}
            description={`las Microfactorias tienen capacidad para producir desde 150 a 10.000 litros sin reponer las bases concentradas. Esto depende del modelo y del producto a fabricar.`}
          />
          <Card
            intro={`Adaptabilidad`}
            description={`Cuentan con una unidad computarizada, la cual permite ajustar la fórmula de los productos y de las mezclas. De este modo se puede fabricar un producto que se adecue a las necesidades demandadas.`}
          />
          <Card
            intro={`Bajo mantenimiento`}
            description={`El sistema funciona únicamente cuando produce, la Microfactoria tiene una gran capacidad de perdurar en el tiempo, sin la necesidad de realizarle mantenimiento de forma constante.`}
          />
          <Card
            intro={`Sistema interactivo`}
            description={`Utilizando el concepto elegir y dispensar, se logra un sistema que solo requiere de esos dos pasos para fabricar un producto. La idea es que el usuario no deba tener grandes conocimientos, simplemente seguir el instructivo establecido.`}
          />
          <Card
            intro={`Sistema de monitoreo,`}
            description={`cuenta con una unidad de control interno que se encarga de brindar reportes en caso de ser necesario. Dicha unidad controla los sistemas de mezcla, bases concentradas, acabado de los productos, presión del agua, entre otros parámetros importantes para el adecuado funcionamiento de la Microfactoria.`}
          />
        </div>
      </div>
    </div>
  );
};

export default Microfactoria;
