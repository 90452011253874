import React from "react";
import PropTypes from "prop-types";

const Heading = ({ numType, text, classes }) => {
  const Style = {
    hx: ` ${classes}`,
  };
  const hX = {
    1: <h1 className={Style.hx}>{text}</h1>,
    2: <h2 className={Style.hx}>{text}</h2>,
    3: <h3 className={Style.hx}>{text}</h3>,
    4: <h4 className={Style.hx}>{text}</h4>,
    5: <h5 className={Style.hx}>{text}</h5>,
    6: <h6 className={Style.hx}>{text}</h6>,
  };

  return hX[numType] ? hX[numType] : hX[1];
};

Heading.propTypes = {
    numType: PropTypes.number.isRequired,
};

export default Heading;
