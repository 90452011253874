import { createContext, useContext, useEffect, useMemo, useReducer } from "react";
import appReducer, { APP_INITIAL_STATE } from "./reducers/app";
import { APP_ACTIONS } from "./actions/types";

const AppContext = createContext();

export const AppProvider = (props) => {
  const [state, dispatch] = useReducer(appReducer, APP_INITIAL_STATE);
  const { currentHomeSection } = state;

  //#region ---- Reactors ----

  useEffect(() => { console.log(currentHomeSection) }, [currentHomeSection]);

  //#endregion ---- Reactors ----

  //#region ---- Data Handlers ----

  const handleSetCurrentHomeSection = (section) => {
    if (section) {
      dispatch({
        type: APP_ACTIONS.SET_CURRENT_HOME_SECTION,
        payload: section,
      });
    } else throw Error("Error al cambiar de sección");
  };

  //#endregion ---- Data Handlers ----

  const getContext = useMemo(() => {
    return { currentHomeSection };
  }, [currentHomeSection]);

  const setContext = useMemo(() => {
    return { handleSetCurrentHomeSection };
  }, []);

  const appState = {
    getContext,
    setContext,
  };

  return <AppContext.Provider value={appState} {...props} />;
};

export const useApp = () => {
  const context = useContext(AppContext);

  if (!context) throw new Error("AppContext Error");

  return context;
};
