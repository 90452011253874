import React from "react";
import useCssClassByViewport from "../../hooks/useCssClassByViewport";

const ImgParagraphRow = ({
  ParagraphComponent,
  svg,
  img,
  imgClasses,
  imgFirst = false,
  containerClasses,
}) => {
  const Style = {
    row: `center ${useCssClassByViewport(
      "py40",
      "",
      ` ${imgFirst ? "" : "rowReverse"}`,
      "",
      ""
    )} ${containerClasses}`,
    imgStyle: {
      backgroundImage: `url(${img})`,
      // // backgroundSize: "cover",
      // backgroundRepeat: "no-repeat",
      // backgroundPosition: "center"//"bottom",
    },
    img: `inlineBlock ${useCssClassByViewport(
      "hide",
      "",
      `${img ? " w32pct " : "hide m0"} ${imgFirst ? "mr8pct" : "ml8pct"}`,
      "",
      ""
    )} ${imgClasses}`,
    paragraph: `inlineBlock ${useCssClassByViewport(
      "hide",
      "",
      "w60pct mr20",
      "",
      ""
    )}`,
  };

  return (
    <div className={Style.row}>
      <div className={img ? Style.img : ''} style={Style.imgStyle}></div>
      {svg && <div> {svg} </div>}
      <div className={Style.paragraph}>{ParagraphComponent}</div>
    </div>
  );
};

export default ImgParagraphRow;
