import React from "react";
import { Link } from "react-scroll";

export const Btn = ({
  classes,
  id,
  text,
  onClick,
  icon,
  iconClass,
  linkTo,
}) => {
  return (
    <>
      {linkTo === "" ? (
        <button id={id} className={classes} onClick={onClick}>
          {text}
          {icon && (
            <div className={`vCenter ml24 ${iconClass || ``}`}>{icon}</div>
          )}
        </button>
      ) : (
        <Link href="#" to={linkTo} spy={true} smooth={true} duration={500} className={classes + " fitContent"}>
          {text}
          <div className={`vCenter ml24 ${iconClass || ``}`}>{icon}</div>
        </Link>
      )}
    </>
  );
};

export default Btn;
