import React from "react";
import Heading from "../components/atoms/Heading";
import ImgParagraphRow from "../components/molecules/ImgParagraphRow";
import Paragraph from "../components/molecules/Paragraph";

const imgUrl = `https://images.unsplash.com/photo-1579546929518-9e396f3cc809?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHx8&w=1000&q=80`;

const MiPyMes = () => {
  const Style = {
    container: `AppPadding py100`,
    paragraphTitle: `title2 txtLeft`,
    paragraphText: ``,
    rowImgs: `h200`,
  };
  return (
    <div className={Style.container}>
      <Heading numType={1} text={`MiPyMes`} classes={`SectionSeparator`} />
      {/* Texto 1 */}
      <ImgParagraphRow
        ParagraphComponent={
          <Paragraph
            titleClass={Style.paragraphTitle}
            titleText={`Optimización y mejoramiento industrial para empresas del mercado químico`}
            paragraphClasses={Style.paragraphText}
            paragraphText={`En industrias que ya dispongan de una planta operativa para su fabricación, un sistema de estas características podría ser muy beneficioso para aumentar el volumen de producción sin tener que ampliar las instalaciones. Permite tener un sistema de producción mixto que sirva para cubrir picos de demanda. De ser requerido se puede migrar en su completitud a un sistema enteramente automático.`}
          />
        }
        // imgFirst={true}
        imgClasses={Style.rowImgs}
        img={imgUrl}
      />
      {/* Texto 2 */}
      <ImgParagraphRow
        ParagraphComponent={
          <Paragraph
            titleClass={Style.paragraphTitle}
            titleText={`Ampliación del mercado meta para empresas del segmento químico`}
            paragraphClasses={Style.paragraphText}
            paragraphText={`La utilización de una Microfactoria reduce el riesgo de una expansión de mercado y los costos asociados. Permite poder crecer como empresa única, o bajo un modelo de franquicia, solo es necesario encargarse de los aspectos comerciales, todo lo relacionado con la parte técnica y química se encarga la Microfactoria. Reduce los costos logísticos de transporte de producto terminado y los costos asociados a la ampliación de instalaciones para la expansión. Dependiendo del modelo a seguir para la expansión se podrá utilizar una Microfactoria fija o móvil.`}
          />
        }
        imgFirst={true}
        imgClasses={Style.rowImgs}
        img={imgUrl}
      />
      {/* Texto 3 */}
      <ImgParagraphRow
        ParagraphComponent={
          <Paragraph
            titleClass={Style.paragraphTitle}
            titleText={`Ampliación de producto en empresas del segmento químico`}
            paragraphClasses={Style.paragraphText}
            paragraphText={`La utilización de una Microfactoria permite la implementación de la filosofía refill, basándonos en los principios de la economía circular. Estas bases son completamente sólidas para ampliar las fronteras comerciales y brindar productos amigables con el medio ambiente. Para el caso de empresas que ya tengan una marca establecida en el mercado, la logística de implementación de esta ampliación de mercado sería muy económica, y permitiría acceder a un nuevo mercado que está en pujante crecimiento.`}
          />
        }
        // imgFirst={true}
        imgClasses={Style.rowImgs}
        img={imgUrl}
      />
      {/* Texto 4 */}
      <ImgParagraphRow
        ParagraphComponent={
          <Paragraph
            titleClass={Style.paragraphTitle}
            titleText={`Diversificación en empresas del segmento químico`}
            paragraphClasses={Style.paragraphText}
            paragraphText={`Combinando la tecnología de las Microfactorias con la filosofía refill, basándonos en los parámetros de la economía circular e incorporando productos biodegradables, se estaría diversificando el propio mercado. Esto permite alcanzar un mercado nuevo con un producto nuevo. La tendencia de consumo en este tipo de productos es creciente, y contar con una infraestructura que permita ser completamente sustentable hace posible mejorar aún más los beneficios que se pueden obtener con este mercado creciente.`}
          />
        }
        imgFirst={true}
        imgClasses={Style.rowImgs}
        img={imgUrl}
      />
      {/* Texto 5 */}
      <ImgParagraphRow
        ParagraphComponent={
          <Paragraph
            titleClass={Style.paragraphTitle}
            titleText={`Diversificación de producto, para empresas de otros segmentos que desean aprovechar las ventajas de este mercado`}
            paragraphClasses={Style.paragraphText}
            paragraphText={`La Microfactoría permite por su facilidad y simplicidad en el uso, poder ampliar las fronteras de producción para cualquier emprendedor o empresario del sector comercial. Sea que por mera similitud de productos o por contar con un público de venta muy masivo. Es tan simple como comprar una fotocopiadora y hacer fotocopias, no requiere de entrenamiento previo, ni tampoco de conocimientos en la materia química para hacerlo. Se puede ampliar la marca conocida por los clientes y ya exitosa en el mercado, y contar con una gama más amplia de productos a disposición, puesto que los productos de limpieza son de consumo recurrente, con esto se estaría optimizando resultados y maximizando ganancias.
            Los modelos a aplicar en estas circunstancias pueden ser: Microfactorias fijas en un local comercial, o Microfactorias móviles instaladas en vehículos permitiendo un mayor acercamiento con el cliente en ferias, ámbitos festivos o eventos ocasionales que puedan surgir en la vía pública.
            `}
          />
        }
        // imgFirst={true}
        imgClasses={Style.rowImgs}
        img={imgUrl}
      />
      {/* Texto 6 */}
      <ImgParagraphRow
        ParagraphComponent={
          <Paragraph
            titleClass={Style.paragraphTitle}
            titleText={`Herramienta que ayude a sentar bases más profundas en materia de responsabilidad social empresarial`}
            paragraphClasses={Style.paragraphText}
            paragraphText={`Dar conocimiento de los criterios de economía circular, la importancia de la reutilización de envases plásticos, el aumento de la contaminación que estos envases de un solo uso genera al medio ambiente. Son una forma de promocionar políticas ambientales para la empresa y ampliar las estructuras de responsabilidad social empresarial. Claramente no hay mejor forma para divulgar una idea o concepto que la utilización práctica del mismo. Por tanto se puede contar con una Microfactoria que reciba envases en un contenedor inteligente, y a su vez detecte la cantidad de envases introducidos, otorgando producto a cambio de ello. Esto puede ser una forma muy intuitiva y elegante de fomentar el criterio de economía circular, favoreciendo a quienes reciclan con producto a elección como forma de intercambio.
            En estos casos es clave acompañar el uso del concepto de reutilización y las bases de la economía circular con una serie de seminarios relacionados en esta materia, para fortalecer los conocimientos en sustentabilidad y economía circular. Paramentos como la reutilización de los plásticos, vida de los mismos y cómo podemos ayudar desde nuestros hogares para disminuir el impacto que estos provocan al entorno son claves en este asunto.
            `}
          />
        }
        imgFirst={true}
        imgClasses={Style.rowImgs}
        img={imgUrl}
      />
    </div>
  );
};

export default MiPyMes;
