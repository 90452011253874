import React, { useRef, useState } from "react";
import useCssClassByViewport from "../../../hooks/useCssClassByViewport";
import { sendContactEmail } from "../../../api/External/sendContactEmail";
import useOnScrollReachElement from "../../../hooks/useOnScrollReachElement";
import Heading from "../../atoms/Heading";

const Contact = ({ contactName, contactTel, contactMail, contactComment }) => {
  const formEl = useRef(null);
  const [sent, setSent] = useState(false);

  const onSubmitContactForm = async (event) => {
    event.preventDefault();
    const { name, phone, email, description } = event.target.elements;
    await sendContactEmail({
      name: name.value,
      phone: phone.value,
      email: email.value,
      description: description.value,
    });
    formEl.current.reset();
    setSent(true);
  };

  useOnScrollReachElement("Contacto");

  return (
    <div
      id="Contacto"
      className="WhiteSection SectionSeparatorTop2"
      style={{
        backgroundImage: "url(/static/media/Map.06ce6c1a.png)",
        backgroundPositionX: "100%",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Heading numType={1} classes={`title1 center`} text={`Quiero saber más!`}/>

      <form
        ref={formEl}
        onSubmit={onSubmitContactForm}
        className={useCssClassByViewport(
          "mAuto w64vw hidden mb144",
          "",
          "mAuto w44vw hidden mb144",
          "",
          ""
        )}
      >
        <div
          className={useCssClassByViewport(
            "block",
            "",
            "center spaceBetween",
            "",
            ""
          )}
        >
          <div
            className={useCssClassByViewport(
              "availableW",
              "",
              "w48pct",
              "",
              ""
            )}
          >
            <label htmlFor="Name">Nombre y Apellido</label>
            <input
              required
              ref={contactName}
              className="mb52 availableW"
              name="name"
              type="text"
              placeholder="ej. Juan López"
            />
          </div>
          <div
            className={useCssClassByViewport(
              "availableW",
              "",
              "w48pct",
              "",
              ""
            )}
          >
            <label htmlFor="Tel">Teléfono</label>
            <input
              required
              ref={contactTel}
              className="mb52 availableW"
              name="phone"
              type="tel"
              title="Ingrese dígitos solamente"
              pattern="/^[0-9]*$/gm"
              placeholder="ej. 099123456"
              // onChange=""
            />
          </div>
        </div>
        <div>
          <div className="availableW">
            <label htmlFor="Email">Email</label>
            <input
              required
              ref={contactMail}
              className="mb52 availableW"
              name="email"
              // onChange={() => handleEmail()}
              type="email"
              placeholder="mimail@mail.com"
            />
          </div>
        </div>
        <label htmlFor="Comentarios">Preguntas o comentarios</label>
        <textarea
          required
          ref={contactComment}
          className="availableW"
          name="description"
          placeholder="Envíenos un mensaje con sus dudas..."
        ></textarea>
        {sent && <p>Formulario enviado con éxito</p>}
        <button disabled={sent} className="btnPrimary mt64 mAuto block">
          Enviar email
        </button>
      </form>
    </div>
  );
};

export default Contact;
