import React from "react";
import Heading from "../components/atoms/Heading";
import ImgParagraphRow from "../components/molecules/ImgParagraphRow";
import Paragraph from "../components/molecules/Paragraph";

const imgUrl = `https://images.unsplash.com/photo-1579546929518-9e396f3cc809?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHx8&w=1000&q=80`;

const Features = () => {
  const Style = {
    container: `AppPadding py100`,
    paragraphTitle: `title2 txtLeft`,
    paragraphText: ``,
    rowImgs: `h140`,
  };
  return (
    <div className={Style.container}>
      <Heading
        numType={1}
        text={`Características relevantes`}
        classes={`SectionSeparator`}
      />

      {/* Característica 1 */}
      <ImgParagraphRow
        ParagraphComponent={
          <Paragraph
            titleClass={Style.paragraphTitle}
            titleText={`Portabilidad y tamaño`}
            paragraphClasses={Style.paragraphText}
            paragraphText={`Tamaño reducido lo cual hace fácil su transporte e instalación en todo tipo de lugares.`}
          />
        }
        imgClasses={Style.rowImgs}
        img={imgUrl}
      />

      {/* Característica 2 */}
      <ImgParagraphRow
        ParagraphComponent={
          <Paragraph
            titleClass={Style.paragraphTitle}
            titleText={`Capacidad de producción`}
            paragraphClasses={Style.paragraphText}
            paragraphText={`Las Microfactorias tienen capacidad para producir desde 300 a 10.000 litros sin reponer las bases concentradas. Esto depende del modelo y del producto a fabricar.`}
          />
        }
        imgClasses={Style.rowImgs}
        imgFirst={true}
        img={imgUrl}
      />

      {/* Característica 3 */}
      <ImgParagraphRow
        ParagraphComponent={
          <Paragraph
            titleClass={Style.paragraphTitle}
            titleText={`Adaptabilidad`}
            paragraphClasses={Style.paragraphText}
            paragraphText={`Cuentan con una unidad computarizada, la cual permite ajustar la fórmula de los productos y de las mezclas. De este modo se puede fabricar un producto que se adecue a las necesidades demandadas.`}
          />
        }
        imgClasses={Style.rowImgs}
        // imgFirst={true}
        img={imgUrl}
      />

      {/* Característica 4 */}
      <ImgParagraphRow
        ParagraphComponent={
          <Paragraph
            titleClass={Style.paragraphTitle}
            titleText={`Bajo mantenimiento`}
            paragraphClasses={Style.paragraphText}
            paragraphText={`El sistema funciona únicamente cuando produce, la Microfactoria tiene una gran capacidad de perdurar en el tiempo, sin la necesidad de realizarle mantenimiento de forma constante.`}
          />
        }
        imgClasses={Style.rowImgs}
        imgFirst={true}
        img={imgUrl}
      />

      {/* Característica 5 */}
      <ImgParagraphRow
        ParagraphComponent={
          <Paragraph
            titleClass={Style.paragraphTitle}
            titleText={`Sistema interactivo`}
            paragraphClasses={Style.paragraphText}
            paragraphText={`Utilizando el concepto elegir y dispensar, se logra un sistema que solo requiere de esos dos pasos para fabricar un producto. La idea es que el usuario no deba tener grandes conocimientos, simplemente seguir el instructivo establecido.`}
          />
        }
        imgClasses={Style.rowImgs}
        // imgFirst={true}
        img={imgUrl}
      />

      {/* Característica 6 */}
      <ImgParagraphRow
        ParagraphComponent={
          <Paragraph
            titleClass={Style.paragraphTitle}
            titleText={`Sistema de monitoreo`}
            paragraphClasses={Style.paragraphText}
            paragraphText={`Cuenta con una unidad de control interno que se encarga de brindar reportes en caso de ser necesario. Dicha unidad controla los sistemas de mezcla, bases concentradas, acabado de los productos, presión del agua, entre otros parámetros importantes para el adecuado funcionamiento de la Microfactoria.`}
          />
        }
        imgClasses={Style.rowImgs}
        imgFirst={true}
        img={imgUrl}
      />
    </div>
  );
};

export default Features;
