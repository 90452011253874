/* eslint-disable no-fallthrough */
import { useState, useEffect } from "react";

const useCssClassByViewport = (xs, sm, md, lg, xl) => {
  const [width, setWidth] = useState(0);
  const [classes, setClasess] = useState("");

  const currentClasses = () => {
    /* Extra small devices (phones, 600px and down) */
    if (width > 0) setClasess(xs);

    /* Small devices (portrait tablets and large phones, 600px and up) */
    if (width > 600 && sm !== "") setClasess(sm);

    /* Medium devices (landscape tablets, 768px and up) */
    if (width > 768 && md !== "") setClasess(md);

    /* Large devices (laptops/desktops, 992px and up) */
    if (width > 992 && lg !== "") setClasess(lg);

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    if (width > 1200 && xl !== "") setClasess(xl);
  };

  function handleResize() {
    setWidth(window.innerWidth);
    currentClasses();
  }

  useEffect(() => {
    //On resize
    window.addEventListener("resize", handleResize);

    // On mount
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return classes;
};

export default useCssClassByViewport;
