import React from "react";
import { Uruguay } from "../../../assets/memoSVG";
import useCssClassByViewport from "../../../hooks/useCssClassByViewport";
import useOnScrollReachElement from "../../../hooks/useOnScrollReachElement";
import Heading from "../../atoms/Heading";
import ImgParagraphRow from "../../molecules/ImgParagraphRow";

const imgUrl = `https://images.unsplash.com/photo-1579546929518-9e396f3cc809?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHx8&w=1000&q=80`;

const About = () => {
  useOnScrollReachElement("About");

  const Style = {
    About: `BlackSection py100 AppPadding vCenter ${useCssClassByViewport("","","minH100vh","","")}`,
  };

  return (
    <div id="About" className={Style.About}>
      <div>
        <Heading numType={1} text={`Quienes somos?`} />
        <div className={`vCenter spaceEvenly ${useCssClassByViewport("column","","row","","")}`}>
          <p className={`w72pct mb0`}>
              Microfactoria es una empresa 100% uruguaya que busca soluciones
              tecnológicas integrales que se adecuen de la mejor manera a las
              necesidades establecidas en el mercado. Nos caracterizamos por ser
              disruptivos en nuestra propuesta de valor y buscamos
              constantemente el mejoramiento continuo. La innovación es la
              herramienta clave para adecuarse al cambio, por tal motivo
              desarrollamos distintos productos propios para un mercado nacional
              de constante crecimiento. Estamos comprometidos a contribuir al
              desarrollo nacional y al crecimiento de la economía del país.
            </p>
            <div className={`center availableH column ${useCssClassByViewport("mt40","","mt0","","")}`}>
              <Uruguay/>
              <span className="mt20 bold">
                Producto Uruguayo
              </span>
            </div>
        </div>
      </div>
    </div>
  );
};

export default About;
