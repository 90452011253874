import { Route, Router, Switch } from "react-router";
import Header from "./components/molecules/header/Header";
import Footer from "./components/molecules/footer/Footer";
import Home from "./pages/Home";
import { createBrowserHistory } from "history";
import Features from "./pages/Features";
import Products from "./pages/Products";
import MiPyMes from "./pages/MiPyMes";
import ScrollToTop from "./utils/ScrollToTop";
import { AppProvider } from "./context/AppContext/AppContext";
//Prueba
function App() {
  const history = createBrowserHistory();
  return (
    <AppProvider>
      <Router history={history}>
        <ScrollToTop>
          <Header />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/caracteristicas" exact component={Features} />
            <Route path="/productos" exact component={Products} />
            <Route path="/mipymes" exact component={MiPyMes} />
            <Route component={Home} />
          </Switch>
          <Footer />
        </ScrollToTop>
      </Router>
    </AppProvider>
  );
}

export default App;
