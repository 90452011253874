import { memo } from "react";
import { ReactComponent as laborLogotype } from "./Company/LaborLogotype.svg";
import { ReactComponent as agranelBrandLogotype } from "./Company/AgranelBrandLogotype.svg";
import { ReactComponent as laborLogo } from "./Company/LaborLogo.svg";
import { ReactComponent as laborLogo2 } from "./Company/LaborLogo2.svg";
import { ReactComponent as agranelLogo } from "./Company/AgranelLogotype.svg";
import { ReactComponent as uruguay } from "./images/Uruguay.svg";
import { ReactComponent as microfactoriaillustration } from "./Company/MicrofactoriaIllustration.svg";

//Page Addons
import { ReactComponent as planta1 } from "./Planta1.svg";
import { ReactComponent as recycle1 } from "./Recycle.svg";
import { ReactComponent as head } from "./Head.svg";
import { ReactComponent as house } from "./House.svg";
import { ReactComponent as bottle } from "./Bottle.svg";
import { ReactComponent as mainBackSVG } from "./MainBackSVG.svg";
import { ReactComponent as ipeLogo } from "./IpeLogo.svg";
import { ReactComponent as map } from "./Map.svg";
import { ReactComponent as logoCater } from "./LogoCater.svg";

export const LaborLogotypeMemo = memo(laborLogotype);
export const AgranelBrandLogotype = memo(agranelBrandLogotype);
export const LaborLogoMemo = memo(laborLogo);
export const LaborLogoMemo2 = memo(laborLogo2);
export const AgranelLogoMemo = memo(agranelLogo);
export const Planta1 = memo(planta1);
export const Recycle1 = memo(recycle1);
export const Head = memo(head);
export const House = memo(house);
export const Bottle = memo(bottle);
export const MainBackSVG = memo(mainBackSVG);
export const Map = memo(map);
export const LogoCater = memo(logoCater);
export const IpeLogo = memo(ipeLogo);
export const Uruguay = memo(uruguay);
export const MicrofactoriaIllustration = memo(microfactoriaillustration);
