import { useEffect } from "react";
import { useApp } from "../context/AppContext/AppContext";

function useOnScrollReachElement(elementId) {
  // Using AppContext
  const { setContext, getContext } = useApp();
  const { handleSetCurrentHomeSection } = setContext;
  const { currentHomeSection } = getContext;

  const handleScroll = () => {
    let element = document.getElementById(elementId);
    if (element !== null) {
      let headerHeight = 88;
      if (window.scrollY === 0) {
        handleSetCurrentHomeSection("Main");
        return;
      }
      if (
        window.scrollY > element.offsetTop - headerHeight &&
        window.scrollY < element.offsetTop + element.clientHeight
      ) {
        handleSetCurrentHomeSection(elementId || "Main");
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", () => handleScroll);
  }, []);
}

export default useOnScrollReachElement;
