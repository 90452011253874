import React from "react";
import Heading from "../components/atoms/Heading";
import Paragraph from "../components/molecules/Paragraph";

const imgUrl = `https://images.unsplash.com/photo-1579546929518-9e396f3cc809?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHx8&w=1000&q=80`;

const Products = () => {
  const Style = {
    container: `AppPadding py100`,
    paragraphTitle: `txtLeft title3 mb4`,
    paragraphText: `SectionSeparator3`,
    rowImgs: `h140`,
  };
  return (
    <div className={Style.container}>
      {" "}
      <Heading numType={1} text={`Productos`} classes={`SectionSeparator2`} />
      <Paragraph
        paragraphClasses={`txtCenter SectionSeparator`}
        paragraphText={`Dependiendo del modelo de Microfactoria que se disponga, será la gama de productos que la misma podrá realizar. Cabe señalar que se está trabajando para ampliar esta lista y poder contar con un mayor número de productos. A continuación establecemos una lista de todos los productos que hasta el momento se pueden hacer con los distintos modelos de Microfactorias en funcionamiento.`}
      />
      
      {/* LISTA DE PRODUCTOS */}
        <Heading text={`Lista de productos`} numType={2} classes={`SectionSeparator2`}/>
      <Paragraph
        titleClass={Style.paragraphTitle}
        paragraphClasses={Style.paragraphText}
        // paragraphText={`Conocido como limpiador multipropósito. Puede ser en una gama amplia de fragancias y colores a elección.`}
        titleText={``}
      />
      <Paragraph
        titleClass={Style.paragraphTitle}
        paragraphClasses={Style.paragraphText}
        paragraphText={`Conocido como limpiador multipropósito. Puede ser en una gama amplia de fragancias y colores a elección.`}
        titleText={`Perfumol`}
      />
      <Paragraph
        titleClass={Style.paragraphTitle}
        paragraphClasses={Style.paragraphText}
        titleText={`Amonio cuaternario`}
        paragraphText={`Para la desinfección de superficies, tratamientos fungicidas y desengrasantes.`}
      />
      <Paragraph
        titleClass={Style.paragraphTitle}
        paragraphClasses={Style.paragraphText}
        titleText={`ClenX`}
        paragraphText={`Desinfecta, limpia, desengrasa y desodoriza. Para aplicar con pulverizador, especialmente desarrollado para la limpieza de baños, cocinas y superficies, telas, alfombras y demás.`}
      />
      <Paragraph
        titleClass={Style.paragraphTitle}
        paragraphClasses={Style.paragraphText}
        titleText={`Limpiador jabonoso para vidrieros`}
        paragraphText={`Es un detergente para vidrieros.`}
      />
      <Paragraph
        titleClass={Style.paragraphTitle}
        paragraphClasses={Style.paragraphText}
        titleText={`Limpiavidrios para hogares y empresas`}
        paragraphText={`De fácil aplicación con pulverizador para la limpieza de todo tipo de vidrios y cristales.`}
      />
      <Paragraph
        titleClass={Style.paragraphTitle}
        paragraphClasses={Style.paragraphText}
        titleText={`Limpiador para parabrisas de auto`}
        paragraphText={`Limpia parabrisas y cristales automovilísticos, con mayor eficiencia de acción. Recomendado para uso en depósitos de automóviles.`}
      />
      <Paragraph
        titleClass={Style.paragraphTitle}
        paragraphClasses={Style.paragraphText}
        titleText={`Desengrasante Ultra`}
        paragraphText={`Para aplicar con pulverizador, desengrasante de alto rendimiento para todo tipo de superficies.`}
      />
      <Paragraph
        titleClass={Style.paragraphTitle}
        paragraphClasses={Style.paragraphText}
        titleText={`Detergente para platos`}
        paragraphText={`Un detergente para vajilla con utilización manual o matic. Presente en varias fragancias y colores.`}
      />
      <Paragraph
        titleClass={Style.paragraphTitle}
        paragraphClasses={Style.paragraphText}
        titleText={`Jabón líquido para ropa`}
        paragraphText={`Un producto apto para máquinas, presente en varias fragancias y colores.`}
      />
      <Paragraph
        titleClass={Style.paragraphTitle}
        paragraphClasses={Style.paragraphText}
        titleText={`Suavizante para ropa`}
        paragraphText={`Un producto apto para todo tipo de ropa. Está disponible en varias fragancias y colores.`}
      />
    </div>
  );
};

export default Products;
